import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Cookies from 'js-cookie';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import FieldRecaptcha from '../../components/FieldRecaptcha/FieldRecaptcha';

import {
  FormattedMessage,
  injectIntl,
  intlShape,
} from '../../util/reactIntl';
import {
  propTypes,
} from '../../util/types';
import {
  isSignupEmailTakenError,
} from '../../util/errors';
import routeConfiguration from '../../routing/routeConfiguration';
import { apiBaseUrl } from '../../util/api';
import { pathByRouteName } from '../../util/routes';
import { setShouldOpenToSession } from '../../util/listing';
import { setFavoriteListingIdToUserProfile } from '../../ducks/user.duck';
import { login, authenticationInProgress, signup, signupWithIdp } from '../../ducks/Auth.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { signModalService } from '../../services/services';
import { SignModalService } from '../../services/SignModal.service';
import { LISTING_TYPE__AIRPLANES } from '../../util/editListingHelpers';

import {
  IconSmallLogo,
  LinkTabNavHorizontal,
  Modal,
  SocialLoginButton,
} from '../../components';
import LoginForm from '../AuthenticationPage/LoginForm/LoginForm';
import SignupForm from '../AuthenticationPage/SignupForm/SignupForm';
import { FacebookLogo, GoogleLogo } from '../AuthenticationPage/socialLoginLogos';
import ConfirmSignupForm from '../AuthenticationPage/ConfirmSignupForm/ConfirmSignupForm';
import InquireForm from './InquireForm';

import css from './SignModal.module.scss';

const getDefaultConfirm = () => {
  const search = new URLSearchParams(window.location.search);
  search.append('signModal', SignModalService.TAB_CONFIRM);
  return `defaultConfirm=${window.location.pathname}?${search.toString()}`;
};

const getDefaultReturn = () => {
  const search = new URLSearchParams(window.location.search);
  const searchStr = search.toString();
  return `defaultReturn=${window.location.pathname}${search.length ? `?${searchStr}` : ''}`;
};

const getModalParams = (modalParams) => {
  if (!modalParams) {
    return null;
  }
  const paramsArr = Object.keys(modalParams).map(key => {
    return modalParams[key] ? `${key}=${modalParams[key]}` : null;
  }).filter(i => !!i);
  return paramsArr.join('&');
};

export class SignModalComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tosModalOpen: false,
      authError: Cookies.get('st-autherror')
        ? JSON.parse(Cookies.get('st-autherror').replace('j:', ''))
        : null,
      authInfo: Cookies.get('st-authinfo')
        ? JSON.parse(Cookies.get('st-authinfo').replace('j:', ''))
        : null,
    };
  }

  componentDidMount() {
    // Remove the autherror cookie once the content is saved to state
    // because we don't want to show the error message e.g. after page refresh
    Cookies.remove('st-autherror');
    this.setState({
      isConfirmTab: new URLSearchParams(window.location.search).get('signModal') === SignModalService.TAB_CONFIRM,
    });
    const search = new URLSearchParams(location.search);
    if (search.get('signModal') === SignModalService.TAB_CONFIRM) {
      search.delete('signModal');
      const newSearch = search.toString();
      const newUrl = `${window.location.pathname}${newSearch ? `?${newSearch}` : ''}`;
      this.props.history.replace(newUrl);
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const search = new URLSearchParams(location.search);
    const signModalParamsAddToFavorites = search.get('signModalParamsAddToFavorites');
    const signModalParamsListingType = search.get('signModalParamsListingType');
    const signModalParamsAuthorId = search.get('signModalParamsAuthorId');
    if (this.props.isAuthenticated
      && signModalParamsAddToFavorites
      && signModalParamsListingType
      && signModalParamsAuthorId) {
      this.props.onSetFavoriteListingIdToUserProfile(
        signModalParamsListingType,
        signModalParamsAuthorId,
        signModalParamsAddToFavorites,
        true
      );
      search.delete('signModalParamsAddToFavorites');
      search.delete('signModalParamsListingType');
      search.delete('signModalParamsAuthorId');
      const newSearch = search.toString();
      const newUrl = `${window.location.pathname}${newSearch ? `?${newSearch}` : ''}`;
      this.props.history.replace(newUrl);
    }
  }

  componentWillUnmount () {
    signModalService.resetState();
  }

  handleCloseModal = () => {
    if (this.props.signModalState.isInquireFunctionality) {
      signModalService.saveVisitors(cloneDeep(this.props.signModalState.modalParams));
    }
    signModalService.closeModal();
    this.setState({ isConfirmTab: false });
    setShouldOpenToSession();
  };

  render() {
    const {
      match,
      intl,
      confirmInProgress,
      isAuthenticated,
      signModalState,
      authInProgress,
      loginError,
      signupError,
      submitLogin,
      submitSignup,
      confirmError,
      onManageDisableScrolling,
      submitSingupWithIdp,
      onSetFavoriteListingIdToUserProfile,
      onCreateTransactionAfterSignup,
    } = this.props;

    if (isAuthenticated) {
      return null;
    }

    const visibleModal = signModalState.isOpen || this.state.isConfirmTab;
    const isLogin = signModalState.tab === SignModalService.TAB_LOGIN;

    const loginErrorMessage = (
      <div className={css.error}>
        <FormattedMessage id="AuthenticationPage.loginFailed" />
      </div>
    );

    const signupErrorMessage = (
      <div className={css.error}>
        {isSignupEmailTakenError(signupError) ? (
          <FormattedMessage id="AuthenticationPage.signupFailedEmailAlreadyTaken" />
        ) : (
          <FormattedMessage id="AuthenticationPage.signupFailed" />
        )}
      </div>
    );

    const confirmErrorMessage = confirmError ? (
      <div className={css.error}>
        {isSignupEmailTakenError(confirmError) ? (
          <FormattedMessage id="AuthenticationPage.signupFailedEmailAlreadyTaken" />
        ) : (
          <FormattedMessage id="AuthenticationPage.signupFailed" />
        )}
      </div>
    ) : null;

    const errorMessage = (error, message) => (error ? message : null);
    const loginOrSignupError = isLogin
      ? errorMessage(loginError, loginErrorMessage)
      : errorMessage(signupError, signupErrorMessage);

    const handleSignInTabClick = () => {
      signModalService.setTab(SignModalService.TAB_LOGIN);
    };

    const handleSignUpTabClick = () => {
      signModalService.setTab(SignModalService.TAB_SIGNUP);
    };

    const tabs = [
      {
        text: (
          <h1 className={css.tab}>
            <FormattedMessage id="AuthenticationPage.signupLinkText" />
          </h1>
        ),
        selected: !isLogin,
        onClick: handleSignUpTabClick,
      },
      {
        text: (
          <h1 className={css.tab}>
            <FormattedMessage id="AuthenticationPage.loginLinkText" />
          </h1>
        ),
        selected: isLogin,
        onClick: handleSignInTabClick,
      },
    ];

    const addToFavorites = () => {
      if (signModalState.modalParams.signModalParamsAddToFavorites) {
        return onSetFavoriteListingIdToUserProfile(
          signModalState.modalParams.signModalParamsListingType,
          signModalState.modalParams.signModalParamsAuthorId,
          signModalState.modalParams.signModalParamsAddToFavorites,
          true
        );
      }
      return Promise.resolve();
    };

    const createTransactionAfterSignup = signModalParams => {
      if (signModalParams.signModalParamsMessage) {
        return onCreateTransactionAfterSignup(signModalParams);
      }
    };

    const handleSubmitLogin = values => {
      submitLogin(values)
        .then(addToFavorites)
        .then(this.handleCloseModal)
        .catch(() => { })
      ;
    };

    const handleSubmitSignup = values => {
      const { fname, lname, ...rest } = values;
      const params = { firstName: fname.trim(), lastName: lname.trim(), ...rest };
      submitSignup(params)
        .then(addToFavorites)
        .then(() => createTransactionAfterSignup(signModalState.modalParams))
        .then(this.handleCloseModal)
        .then(() => FieldRecaptcha.restart())
        .catch(() => { })
      ;
    };

    const handleSubmitConfirm = values => {
      const { idpToken, email, firstName, lastName, idpId } = this.state.authInfo;
      const { email: newEmail, firstName: newFirstName, lastName: newLastName, userRole, ...rest } = values;

      // Pass email, fistName or lastName to Flex API only if user has edited them
      // sand they can't be fetched directly from idp provider (e.g. Facebook)

      const authParams = {
        ...(newEmail !== email && { email: newEmail }),
        ...(newFirstName !== firstName && { firstName: newFirstName }),
        ...(newLastName !== lastName && { lastName: newLastName }),
      };

      // If the confirm form has any additional values, pass them forward as user's protected data
      const protectedData = !isEmpty(rest) ? { ...rest } : null;

      const publicData = { userRole };
      const search = new URLSearchParams(window.location.search);
      const signModalParams = Object.fromEntries(search);

      submitSingupWithIdp({
        idpToken,
        idpId,
        publicData,
        ...authParams,
        ...(!!protectedData && { protectedData }),
      })
        .then(() => createTransactionAfterSignup(signModalParams))
        .then(() => {
          Object.keys(signModalParams).forEach(key => {
            search.delete(key);
          });
          const newSearch = search.toString();
          const newUrl = `${window.location.pathname}${newSearch ? `?${newSearch}` : ''}`;
          this.props.history.replace(newUrl);
          return Promise.resolve();
        })
        .then(this.handleCloseModal)
        .catch(() => { })
      ;
    };

    const getDefaultRoutes = () => {
      const routes = routeConfiguration();
      const baseUrl = apiBaseUrl();

      // Route where the user should be returned after authentication
      // This is used e.g. with EditListingPage and ListingPage
      const fromParam = '';

      // Default route where user is returned after successfull authentication
      const defaultReturn = pathByRouteName('LandingPage', routes);
      const defaultReturnParam = defaultReturn ? `&defaultReturn=${defaultReturn}` : '';

      // Route for confirming user data before creating a new user
      const defaultConfirm = pathByRouteName('ConfirmPage', routes);
      const defaultConfirmParam = defaultConfirm ? `&defaultConfirm=${defaultConfirm}` : '';

      return { baseUrl, fromParam, defaultReturnParam, defaultConfirmParam };
    };

    const authWithFacebook = () => {
      const { baseUrl } = getDefaultRoutes();
      const search = [
        getDefaultConfirm(),
        getDefaultReturn(),
        getModalParams(signModalState.modalParams)
      ].filter(i => !!i).join('&');
      window.location.href = `${baseUrl}/api/auth/facebook?${search}`;
    };

    const authWithGoogle = () => {
      const { baseUrl } = getDefaultRoutes();
      const search = [
        getDefaultConfirm(),
        getDefaultReturn(),
        getModalParams(signModalState.modalParams),
      ].filter(i => !!i).join('&');
      window.location.href = `${baseUrl}/api/auth/google?${search}`;
    };

    const idp = this.state.authInfo
      ? this.state.authInfo.idpId.replace(/^./, str => str.toUpperCase())
      : null
    ;

    // Form for confirming information frm IdP (e.g. Facebook)
    // before new user is created to Flex
    const confirmForm = (
      <div className={css.content}>
        <div className={css.contentHeading}>
          <IconSmallLogo />
          <h2>
            <FormattedMessage id="AuthenticationPage.flyingTitle" />
          </h2>
          <p>
            <FormattedMessage id="AuthenticationPage.confirmSignupInfoText" />
          </p>
        </div>

        {confirmErrorMessage}
        <ConfirmSignupForm
          className={css.form}
          onSubmit={handleSubmitConfirm}
          inProgress={confirmInProgress}
          onOpenTermsOfService={() => this.setState({ tosModalOpen: true })}
          authInfo={this.state.authInfo}
          idp={idp}
        />
      </div>
    );

    // Social login buttons
    const showFacebookLogin = !!process.env.REACT_APP_FACEBOOK_APP_ID;
    const showGoogleLogin = !!process.env.REACT_APP_GOOGLE_CLIENT_ID;
    const showSocialLogins = showFacebookLogin || showGoogleLogin;

    const facebookButtonText = isLogin ? (
      <FormattedMessage id="AuthenticationPage.loginWithFacebook" />
    ) : (
      <FormattedMessage id="AuthenticationPage.signupWithFacebook" />
    );

    const googleButtonText = isLogin ? (
      <FormattedMessage id="AuthenticationPage.loginWithGoogle" />
    ) : (
      <FormattedMessage id="AuthenticationPage.signupWithGoogle" />
    );
    const socialLoginButtonsMaybe = showSocialLogins ? (
      <div className={css.idpButtons}>
        {showFacebookLogin ? (
          <div className={css.socialButtonWrapper}>
            <SocialLoginButton onClick={() => authWithFacebook()}>
              <span className={css.buttonIcon}>{FacebookLogo}</span>
              {facebookButtonText}
            </SocialLoginButton>
          </div>
        ) : null}

        {showGoogleLogin ? (
          <div className={css.socialButtonWrapper}>
            <SocialLoginButton onClick={() => authWithGoogle()}>
              <span className={css.buttonIcon}>{GoogleLogo}</span>
              {googleButtonText}
            </SocialLoginButton>
          </div>
        ) : null}

        <div className={css.socialButtonsOr}>
          <span className={css.socialButtonsOrText}>
            <FormattedMessage id="AuthenticationPage.or" />
          </span>
        </div>
      </div>
    ) : null;

    const signupFormAttrs = {
      className: css.signupForm,
      onSubmit: handleSubmitSignup,
      inProgress: authInProgress,
      onOpenTermsOfService: () => this.setState({ tosModalOpen: true }),
      ...(signModalState.modalParams.signModalParamsEmail
        && signModalState.modalParams.signModalParamsName
        && signModalState.modalParams.signModalParamsLastName && {
        initialValues: {
          email: signModalState.modalParams.signModalParamsEmail,
          fname: signModalState.modalParams.signModalParamsName,
          lname: signModalState.modalParams.signModalParamsLastName,
        },
      }),
    };
    const authenticationForms = (
      <div className={css.content}>
        {signModalState.isListingViewerSignupFunctionality && (
          <>
            <h2 className={css.centeredText}>Join Aircraft For Sale, Free!</h2>
            <p className={css.centeredText}>
              Track price change alerts, find the best deals, and save your favorite listings for later.
            </p>
          </>
        )}
        {signModalState.isAddToFavoritesFunctionality && (
          <LinkTabNavHorizontal
            className={css.tabs}
            tabs={tabs}
          />
        )}
        {signModalState.isInquireFunctionality && (
          <>
            <h2 className={css.centeredText}>
              Sign up for Aircraft for Sale
            </h2>
            <p className={css.centeredText}>
              Track your favorite aircraft, save relevant searches,
              and communicate directly with sellers when you join Aircraft for Sale
            </p>
          </>
        )}
        {signModalState.isPricingAnalysisFunctionality && (
          <h2 className={css.centeredText}>
            Sign in to make your opinion count
          </h2>
        )}

        {socialLoginButtonsMaybe}

        {isLogin ? (
          <LoginForm
            className={css.loginForm}
            onSubmit={handleSubmitLogin}
            inProgress={authInProgress}
            loginOrSignupError={loginOrSignupError}
          />
        ) : (
          <SignupForm
            {...signupFormAttrs}
            loginOrSignupError={loginOrSignupError}
          />
        )}
        <div className={css.notNowLinkWrapper}>
          <a onClick={this.handleCloseModal}>No thanks, not right now</a>
        </div>
      </div>
    );

    const onSubmitInquireForm = values => {
      return signModalService.onSubmitInquireForm(values, match.params);
    };

    const inquireContent = (
      <InquireForm
        intl={intl}
        sendEnquiryInProgress={signModalState.sendInquireInProgress}
        sendEnquiryError={signModalState.sendInquireError}
        listingTitle=''
        onSubmit={onSubmitInquireForm}
      />
    );
    const formContent = this.state.isConfirmTab ? confirmForm : authenticationForms;
    const tabContent = signModalState.isSignContent ? formContent : inquireContent;

    return (
      <Modal
        id="signModal"
        containerClassName={css.root}
        scrollLayerClassName={css.scrollLayer}
        isOpen={visibleModal}
        onClose={this.handleCloseModal}
        usePortal={false}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        {tabContent}
      </Modal>
    );
  }
}

SignModalComponent.defaultProps = {
  loginError: null,
  signupError: null,
  confirmError: null,
  showSocialLoginsForTests: false,
};

const { bool, func, object, shape } = PropTypes;

SignModalComponent.propTypes = {
  authInProgress: bool.isRequired,
  isAuthenticated: bool.isRequired,
  loginError: propTypes.error,
  scrollingDisabled: bool.isRequired,
  signupError: propTypes.error,
  confirmError: propTypes.error,
  confirmInProgress: bool.isRequired,
  submitLogin: func.isRequired,
  submitSignup: func.isRequired,

  onManageDisableScrolling: func.isRequired,

  // from withRouter
  location: shape({ state: object }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    isAuthenticated,
    loginError,
    signupError,
    confirmError,
    confirmInProgress,
  } = state.Auth;
  return {
    authInProgress: authenticationInProgress(state),
    isAuthenticated,
    loginError,
    scrollingDisabled: isScrollingDisabled(state),
    signupError,
    confirmError,
    confirmInProgress,
    signModalState: signModalService.$signModalState(state),
  };
};

const mapDispatchToProps = dispatch => ({
  submitLogin: ({ email, password }) => dispatch(login(email, password)),
  submitSignup: params => dispatch(signup(params)),
  submitSingupWithIdp: params => dispatch(signupWithIdp(params)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onSetFavoriteListingIdToUserProfile: (lType, authId, lId, onlyAdd) =>
    dispatch(setFavoriteListingIdToUserProfile(lType, authId, lId, onlyAdd)),
  onCreateTransactionAfterSignup: signModalParams =>
    dispatch(signModalService.createTransactionAfterSignup(signModalParams)),
});

const SignModal = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(SignModalComponent);

export default SignModal;
